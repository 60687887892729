import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import badgeAzitek from '../assets/images/badge-azitek.jpg';
import filipe_silva from '../assets/images/filipe_silva.jpeg';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 3.75rem;

  .fake-header-wrapper {
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      font-size: 1.8rem;
      width: 950px;
      padding-bottom: 2rem;
      line-height: 1;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle}

    h2 {
      text-align: center;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
        align-self: center;
      }

      @media (min-width: ${media.newsTextWidth}px) {
        width: 950px;
      }
    }
    h3 {
      font-size: 1.6rem;
    }
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: ${media.newsTextWidth}px) {
    width: 950px;
  }

  .image-midsection {
    @media (min-width: ${media.newsTextWidth}px) {
      display: flex;
      div {
        width: 100%;
      }
      img {
        width: 80%;
      }
    }
    @supports not (-moz-appearance: none) {
      @media (min-width: ${media.newsTextWidth}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;

const Signature = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  img {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  img {
    width: 100%;
    max-width: 950px;

    @media (min-width: ${media.navMaxLength}px) {
      width: 950px;
    }

    margin-bottom: 2rem;
    align-self: center;
  }

  ${TextContainer} {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1 1 100%;
    padding-bottom: 2rem;
    align-self: center;
  }

  .gatsby-image-wrapper {
    width: 50%;
    margin: 2rem auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: column-reverse;

    ${TextContainer} {
      font-size: 1.2rem;
      line-height: 1.8;
      flex: 1 0 55%;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2.5rem;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${TextContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function NewsPageComponent({ backgroundColor = colors.lightGrey }) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <div className="fake-header-wrapper">
          <p>
            <strong>
              Exploring how Azitek&#39;s technology is aligned with the
              expanding needs of RTIs and Tugger Trains
            </strong>
          </p>
        </div>

        <HowItWorksContainer>
          <NewsWrapper>
            <TextContainer>
              <p>
                Azitek has been recognized as a key player in the indoor
                location market for the second consecutive year, as a top key
                player in the{' '}
                <strong>
                  <a href="https://www.marketsandmarkets.com/Market-Reports/indoor-location-market-989.html">
                    MarketsandMarkets report
                  </a>
                </strong>
                . The{' '}
                <strong>
                  indoor location market is experiencing rapid growth
                </strong>
                , projected to expand from $10.9 billion in 2023 to $29.8
                billion by 2028, driven by a{' '}
                <strong>Compound Annual Growth Rate (CAGR) of 22.3%</strong>.
                This surge is fueled by the increasing need for precision
                tracking and management of assets within complex industrial
                environments.
              </p>

              <p>
                As we celebrate this recognition, we also take this opportunity
                to reflect on our strategic position in the market and how our
                growth aligns with the expanding demand for returnable transport
                items (RTI&#39;s) and material handling equipment, such as{' '}
                tugger trains and forklifts.
              </p>

              <h3>
                Growing Together with the RTI&#39;s and Tugger Train Markets
              </h3>
              <p>
                As industries increasingly adopt reusable packaging and update
                logistics through automated tracking solutions, the market for{' '}
                Returnable Transport Items (RTIs) is witnessing robust growth.
                According to{' '}
                <strong>
                  <a href="https://www.futuremarketinsights.com/reports/returnable-transport-packaging-market">
                    Future Market Insights
                  </a>
                </strong>
                , the{' '}
                <strong>
                  RTI&#39;s market is expected to continue expanding{' '}
                </strong>{' '}
                due to the growing emphasis on <strong>sustainability</strong>{' '}
                and <strong>cost efficiency</strong>. Azitek&#39;s technology is
                specifically designed to address the challenges associated with
                RTIs, such as visibility issues and the high rate of loss, which
                traditionally stands at around 15% annually. By offering{' '}
                <strong>real-time monitoring</strong> and{' '}
                <strong>advanced analytics</strong>, our solutions not only
                improve operational efficiency but also significantly reduce
                asset loss, providing a strong return on investment for our
                clients.
              </p>

              <p>
                According to a recent market report by{' '}
                <strong>
                  <a href="https://www.businessresearchinsights.com/market-reports/tugger-train-market-114380">
                    Business Research Insights
                  </a>
                </strong>
                , the{' '}
                <strong>
                  tugger train market is expected to experience significant
                  growth{' '}
                </strong>{' '}
                as industries increasingly adopt these systems for efficient
                internal logistics. Azitek&#39;s{' '}
                <strong>material handling vehicle monitoring solution</strong>{' '}
                is specifically tailored to meet the demands of this expanding
                market. Our system offers adaptability, allowing for{' '}
                <strong>real-time tracking</strong> and{' '}
                <strong>management of tugger trains</strong>. Additionally, our
                solution provides detailed analytics on vehicle usage and milk
                run routes, enabling companies to identify and eliminate
                inefficiencies,{' '}
                <strong>optimizing their internal logistics processes</strong>.
              </p>
              <h3>Aligning with Market Growth and Future Needs</h3>
              <br />
              <p>
                The indoor location market, the RTI&#39;s sector, and the{' '}
                material handling equipment market{' '}
                <strong>are all experiencing robust growth</strong>, driven by{' '}
                technological advancements and the increasing need for{' '}
                efficient, sustainable logistics solutions. Azitek&#39;s
                technology is positioned to maximize on these trends, which
                means that our solutions are not only tailored to address the
                current challenges faced by these industries but are also
                designed to adapt and scale with future needs. As we continue to
                grow alongside these expanding industries, we remain committed
                to delivering technologies that{' '}
                <strong>
                  improve efficiency, sustainability, and safety in
                  manufacturing and logistics operations.
                </strong>
              </p>
              <p></p>
              <Signature>
                <img src={filipe_silva} />
                <p>
                  <strong>
                    Filipe Silva, Business Developer
                    <br /> Aug 2024
                  </strong>
                </p>
              </Signature>
            </TextContainer>
            <img
              className="origin-story-img"
              src={badgeAzitek}
              imgStyle={{ height: 'auto' }}
            />
          </NewsWrapper>
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

NewsPageComponent.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default NewsPageComponent;
